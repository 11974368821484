.booking-success, .payment-method, .payment-user, .register-page, .account-details-page, .gallery-list, 
.cancel-booking-reason-page, .change-password-page, .search-component, .purchase-success, .user-login,
.affiliate-booking-success, .create-password-page, .guide-page-calendar{
    .header-navigation{
        position: relative;
    }
}

.adventure-book, .adventure-duration, .guide-page, .partner-page, 
.login-choose, .user-login, .create-adventure-page, .adventure-availability-page, .update-password-page{
    .header-navigation{
        .back-button{
            background: url(.\..\icons\icon_back.png) center no-repeat;
        }   
        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
            .back-button {
                background-image: url('https://api.iconify.design/ion:ios-close.svg?color=%23FFFFFF&height=30');
                background-size: 40px;
            }
        }
    }
}

.adventure-book, .adventure-duration, .gallery-swiper, .explore-component, .guide-page, .login-choose, 
.user-login, .partner-page, .adventure-credits, .create-adventure-page, .main-loading-screen, .blog-page, .activity-page, 
.region-page, .guide-public-page, .adventure-availability-page, .view-this-adventure, .update-password-page, .category-page{
    .header-navigation{
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%);
        .adventure-logo {
            background: url(.\..\icons\logo_main_white.png) 0 no-repeat;
        }
        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
            .adventure-logo {
                background-image: url(.\..\icons\logo_main_white@2x.png);
            }
        }
    }
}

.section-title {		
    font-family: Heebo;	
    font-size: 24px;	
    font-weight: bold;	
    line-height: 35px;
}

.explore-component, .main-loading-screen {
    .bucket-component, .partners-component, .tall-adventures, .gallery-component{
        max-width: 900px;
        margin: 0 auto;
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (max-width: 767.98px) {
    .explore-component {
        .top-guides, .nearby-component, .bucket-component, .partners-component, .tall-adventures, .gallery-component, .top-partners, .latest-stories{
            padding-left: 13pt !important;
            padding-right: 0;
        }
    }
}

.price-rectangle{
    border-radius: 4px;
    background-color: #8FC0C3;
    color: #FFFFFF;
    font-family: Heebo;
    font-size: 11px;
    font-weight: bold;
    height: 1.25rem;
    align-self: flex-end;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: 10px;
    margin-left: 10px;
    line-height: 20px;
}

.form-control:focus{
    box-shadow: none;
}

.form-control::placeholder{
    color: #BDC3C7 !important;
}

.btn-submit, .btn-cancel{
    border: none;
    border-radius: 2rem;
    padding: 0.75rem;
    margin-left: 0;
    margin-right: 0;
    font-size: 18px;
    margin-top: 1rem;
    background-color: #000000 !important;
    text-transform: none;
    color: #FFFFFF;
    width: 20rem;
    box-shadow: none !important;
}

.btn-submit:hover{
    color: #FFFFFF;
}

.btn-submit:focus{
    outline: none;
}

.conversation-component, .message-screen, .following-adventures-page, .following-guides-page, .following-partners-page, 
.following-page, .register-page, .my-adventures, .view-receipt-page, .cancel-booking-page, .cancel-booking-reason-page,
.manage-friends-page, .add-friends-page, .booking-success, .payment-method, .payment-user, .change-password-page, .interests-page,
.guide-reviews-page, .review-adventure-page, .search-component, .adventure-credits, .buy-adventure-credits, .purchase-success,
.referral-code-component, .guide-registration, .registration-approval, .main-guide-screen, .guide-adventures, .blog-page, 
.manage-availability-page, .manage-guest-waivers, .guest-waivers{
    height: 100%;
    min-height: calc(var(--vh, 1vh) * 100);
    background-color: #FFFFFF;
    font-family: Heebo;
}

.adv-popover{
    .popover{
        border: none;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    }
}

.bottom-line{
    height: 1px;
    max-width: 870px;
    opacity: 0.1;
    background-color: #1A1A1A;
    margin: 0 auto;
    width: 100%;
}

@media (max-width: 991.98px) {
    .bottom-line {
      visibility: hidden;
    }
    .page-footer{
        display: none;
    }
}

.adventure-duration, .adventure-book, .adventure-availability-page{
    .bottom-line {
        background-color: #ffffff;
        width: 100%;
    }
    .footer-component .footer-title {
        background: url(.\..\icons\logo_footer_white.png) 0 no-repeat;
        background-size: contain;
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .footer-component .footer-title {
          background-image: url(.\..\icons\logo_footer_white@2x.png);
        }
    }
}

.form-control:active, .form-control:focus{
    border: 1px solid #ced4da !important;
}

a:focus{
    outline: none;
}

a:hover{
    text-decoration: none;
}

.page-wrapper{
    height: 100%;
    min-height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
    width: calc(100vw - 17px);
    @media (max-width: 767.98px){
        width: 100%;
        max-width: 100vw;
    }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
        width: 100%;
        max-width: 100vw;
    }
    
}